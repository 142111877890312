import './Footer.css'

const Footer = () => {
  return (
    <div className='footer'>
      <p>Copyright © 2023 Clare Cerullo</p>
    </div>
  )
}

export default Footer;